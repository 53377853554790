import Image from "next/image";
import Link from "next/link";
import React, { useState, useEffect } from "react";
import Script from "next/script";
import Head from "next/head";

import "../../public/static/css/animate.css";
import "../../public/static/css/icomoon.css";
import "../../public/static/css/bootstrap.css";
import "../../public/static/css/style.css";
import Modal from "../components/modal";

function Home() {
  const [data, setData] = useState(null);

  const callAPI = async () => {
    try {
      fetch(`https://api.febriansyah.dev/visitor.php`);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    callAPI();
  });

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="deScription" content="Freelance Web Developer specializing in Node.js and Flutter. Offering expertise in building responsive and dynamic web and mobile applications." />
        {/* <meta name="keywords" content="Freelance, Web Developer, Node.js, Flutter, Responsive Web, Mobile Apps, mobile developer indonesia, indonesian mobile developer, asian mobile developer, web developer indonesia, indonesian web developer, asian mobile developer, fullstack developer indonesia, indonesian fullstack developer, asian fullstack developer, flutter developer indonesia, indonesian flutter developer, asian flutter developer, freelance developer indonesia, freelance developer, nodejs developer, node.js developer, socket developer, chat application developer, websocket developer" /> */}
        <meta name="keywords" content="Freelance Web Developer, Node.js Developer, Flutter Developer, Fullstack Developer, Mobile App Developer, Web Developer, Indonesia Developer, Asian Developer, Socket.io Developer, WebSocket Developer, Chat App Developer, Remote Developer, Responsive Web Developer" />

        <meta name="author" content="Febriansyah.dev" />
        <title>Febriansyah - Fullstack Developer (Mobile & Web Developer)</title>
        {/* 
                //////////////////////////////////////////////////////

                FREE HTML5 TEMPLATE 
                DESIGNED & DEVELOPED by FreeHTML5.co
                    
                Website: 		http://freehtml5.co/
                Email: 			info@freehtml5.co
                Twitter: 		http://twitter.com/fh5co
                Facebook: 		https://www.facebook.com/fh5co

                //////////////////////////////////////////////////////
                */}
        {/* Facebook and Twitter integration */}
        <link href="https://fonts.googleapis.com/css?family=Space+Mono" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Kaushan+Script" rel="stylesheet" />
      </Head>

      <Script src="/static/js/jquery.min.js" strategy="beforeInteractive"></Script>
      <Script src="/static/js/jquery.easing.1.3.js" strategy="beforeInteractive"></Script>
      <Script src="/static/js/bootstrap.min.js" strategy="beforeInteractive"></Script>
      <Script src="/static/js/jquery.waypoints.min.js" strategy="beforeInteractive"></Script>
      <Script src="/static/js/jquery.stellar.min.js" strategy="beforeInteractive"></Script>
      <Script src="/static/js/jquery.easypiechart.min.js" strategy="beforeInteractive"></Script>
      <Script src="/static/js/main.js"></Script>
      {/* <div className="fh5co-loader" /> */}

      <div id="page">
        <header
          id="fh5co-header"
          className="fh5co-cover js-fullheight"
          role="banner"
          style={{
            backgroundImage: "url(static/images/cover_bg_3.jpg)",
          }}
          data-stellar-background-ratio="0.5"
        >
          <div className="overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center">
                <div className="display-t js-fullheight">
                  <div className="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
                    <div
                      className="profile-thumb"
                      style={{
                        background: "url(static/images/profile.jpeg)",
                      }}
                    />
                    <h1>
                      <span>Febriansyah</span>
                    </h1>
                    <h3>
                      <span>Fullstack Developer (Mobile & Web Developer)</span>
                    </h3>
                    <p></p>
                    <ul className="fh5co-social-icons">
                      <li>
                        <Link href="https://wa.me/628128182012">
                          <Image src="/static/images/whatsapp.png" width={30} height={30} alt="628128182012" />
                        </Link>
                      </li>
                      <li>
                        <Link href="https://id.linkedin.com/in/febriansyah-497b60143">
                          <Image src="/static/images/linkedIn.png" width={30} height={30} alt="https://id.linkedin.com/in/febriansyah-497b60143" />
                        </Link>
                      </li>
                      <li>
                        <Link href="https://github.com/iam-febriansyah">
                          <Image src="/static/images/github.png" width={30} height={30} alt="https://github.com/iam-febriansyah" />
                        </Link>
                      </li>
                      <li>
                        <Link href="https://www.fiverr.com/febriansyah_dev">
                          <Image src="/static/images/fiverr.png" width={30} height={30} alt="https://www.fiverr.com/febriansyah_dev" />
                        </Link>
                      </li>
                      <li>
                        <Link href="https://www.upwork.com/freelancers/~01540f97f53b6b69f3">
                          <Image src="/static/images/upwork.svg" width={30} height={30} alt="https://www.upwork.com/freelancers/~01540f97f53b6b69f3" />
                        </Link>
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="fh5co-about" className="animate-box">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2>About Me</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <ul className="info">
                  <li>
                    <span className="first-block">Full Name:</span>
                    <span className="second-block">Febriansyah</span>
                  </li>
                  <li>
                    <span className="first-block">Phone:</span>
                    <span className="second-block">+62 812 818 2012</span>
                  </li>
                  <li>
                    <span className="first-block">Email 1:</span>
                    <span className="second-block">febriansyah032@gmail.com</span>
                  </li>
                  <li>
                    <span className="first-block">Email 2:</span>
                    <span className="second-block">febriansyah.dev@gmail.com</span>
                  </li>
                  <li>
                    <span className="first-block">Address:</span>
                    <span className="second-block">Cluster Berkah Cimuning Residence, No.7, Mustika Jaya, KotaBekasi, Jawa Barat - Indonesia</span>
                  </li>
                  <li>
                    <span className="first-block">Github:</span>
                    <span className="second-block">
                      <Link href="https://github.com/iam-febriansyah" style={{ cursor: "pointer" }}>
                        iam-febriansyah
                      </Link>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-md-8">
                {/* <h2>Hello There!</h2>
                <p>Hello! My name is Febriansyah. I am a devout Muslim who has been on a remarkable journey in the world of programming since 2016. My expertise primarily lies in backend development using Node.js, but I am also proficient in creating mobile applications with Flutter and fine-tuning database queries, particularly in SQL Server and other similar platforms.</p>
                <p>In addition to my professional life, I am a devoted family person. I am happily married and blessed with a wonderful son who brings immense joy to my life.</p>
                <p>Currently, I am fully committed to my role at an automotive manufacturing company where I contribute my programming skills to create innovative solutions. However, my passion for programming extends beyond my full-time job. I am actively engaged in the world of freelance work on platforms like Fiverr, where I continue to expand my horizons and collaborate with clients from diverse backgrounds.</p>
                <p>My journey as a programmer has been both fulfilling and inspiring, and I am excited about the opportunities that lie ahead. I look forward to connecting with like-minded individuals and exploring new avenues for growth and learning. Thank you for taking the time to get to know me.</p>
                <br/><br/> */}

                <h2>Hello There!</h2>
                <p>
                    Hello! My name is <strong>Febriansyah</strong>, a dedicated <strong>freelance web developer</strong> and <strong>mobile app developer</strong> with expertise in <strong>Node.js, Laravel</strong> and <strong>Flutter</strong>. Since 2016, I have been crafting high-performance applications, specializing in <strong>backend development</strong> and fine-tuning <strong>SQL queries</strong>.
                </p>

                <h3>My Skills & Expertise</h3>
                <ul>
                    <li><strong>Backend Development</strong> – Node.js, Laravel, WebSocket, Socket.io</li>
                    <li><strong>Mobile App Development</strong> – Flutter</li>
                    <li><strong>Database Management</strong> – SQL Server, MySQL, PostgreSQL</li>
                    <li><strong>Freelancing</strong> – Fiverr, Remote Projects</li>
                </ul>

                <h3>Professional Journey</h3>
                <p>
                    Currently, I am working full-time at an <strong>manufacturing company</strong>, contributing my programming expertise to build innovative solutions. However, my passion extends beyond my job—I actively take on <strong>freelance projects</strong> through <strong><Link href="https://www.fiverr.com/febriansyah_dev">Fiverr</Link></strong>, collaborating with clients worldwide.
                </p>

                <h3>Let’s Connect!</h3>
                <p>
                    I am always open to new opportunities, whether it's a <strong>freelance web development</strong> project or a challenging <strong>backend system</strong>. Let’s work together to build something great!
                </p>

                <p>
                    <strong>#WebDeveloper #FlutterDeveloper #NodejsDeveloper #FreelanceDeveloper #SocketioDeveloper #WebSocketDeveloper</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="fh5co-resume" className="fh5co-bg-color">
          <div className="container">
            <div className="row animate-box">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2>My Resume</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-md-offset-0">
                <ul className="timeline">
                  <li className="timeline-heading text-center animate-box">
                    <div>
                      <h3>Work Experience</h3>
                    </div>
                  </li>

                  <li className="timeline-inverted animate-box">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">IT Custom Application Supervisor</h3>
                        <span className="company">Sukanda Djaya, PT - Diamond Group : Mar 2024 - Present</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">PHP</button>
                        <button className="btn btn-success btn-nopadding">Laravel</button>
                        <button className="btn btn-success btn-nopadding">Flutter</button>
                        <button className="btn btn-success btn-nopadding">Python</button>
                        <button className="btn btn-success btn-nopadding">MySql</button>
                        <button className="btn btn-success btn-nopadding">PostgreSQL</button>
                        <button className="btn btn-success btn-nopadding">Design UI</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                        <button className="btn btn-success btn-nopadding">Flow Chart</button>
                      </div>
                    </div>
                  </li>

                  <li className="animate-box timeline-unverted">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">IT Programmer</h3>
                        <span className="company">Musashi Auto Parts Indonesia : Feb 2023 - Feb 2024</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">Node.JS</button>
                        <button className="btn btn-success btn-nopadding">EJS</button>
                        <button className="btn btn-success btn-nopadding">Flutter</button>
                        <button className="btn btn-success btn-nopadding">SQL Server</button>
                        <button className="btn btn-success btn-nopadding">Node-Red</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                        <button className="btn btn-success btn-nopadding">Flow Chart</button>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted animate-box">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">Lead of Programmer</h3>
                        <span className="company">Galeri Teknologi Bersama : May 2021 - Jan 2023</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">Node.JS</button>
                        <button className="btn btn-success btn-nopadding">Flutter</button>
                        <button className="btn btn-success btn-nopadding">PHP</button>
                        <button className="btn btn-success btn-nopadding">Laravel</button>
                        <button className="btn btn-success btn-nopadding">CodeIgniter</button>
                        <button className="btn btn-success btn-nopadding">Kotlin</button>
                        <button className="btn btn-success btn-nopadding">EJS</button>
                        <button className="btn btn-success btn-nopadding">MySql</button>
                        <button className="btn btn-success btn-nopadding">PostgreSQL</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                        <button className="btn btn-success btn-nopadding">Flow Chart</button>
                      </div>
                    </div>
                  </li>

                  <li className="animate-box timeline-unverted">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">Developer</h3>
                        <span className="company">Meikarta : Jan 2021 - May 2021</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">Flutter</button>
                        <button className="btn btn-success btn-nopadding">PHP</button>
                        <button className="btn btn-success btn-nopadding">Laravel</button>
                        <button className="btn btn-success btn-nopadding">MySql</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted animate-box">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">System Analyst &amp; Developer</h3>
                        <span className="company">Mitra Integrasi Informatika : Sept 2019 - Dec 2020</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">SQL Server</button>
                        <button className="btn btn-success btn-nopadding">SSRS</button>
                        <button className="btn btn-success btn-nopadding">SSIS</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                        <button className="btn btn-success btn-nopadding">Flow Chart</button>
                        <button className="btn btn-success btn-nopadding">Flutter</button>
                      </div>
                    </div>
                  </li>

                  <li className="animate-box timeline-unverted">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">System Analyst</h3>
                        <span className="company">Berca Hardayaperkasa : Sep 2018 - Dec 2018</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">SQL Server</button>
                        <button className="btn btn-success btn-nopadding">SSRS</button>
                        <button className="btn btn-success btn-nopadding">SSIS</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                        <button className="btn btn-success btn-nopadding">Flow Chart</button>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted animate-box">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">System Analyst</h3>
                        <span className="company">ISS Indonesia, PT : Apr 2018 - Aug 2018</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">SQL Server</button>
                        <button className="btn btn-success btn-nopadding">SSRS</button>
                        <button className="btn btn-success btn-nopadding">SSIS</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                        <button className="btn btn-success btn-nopadding">Flow Chart</button>
                      </div>
                    </div>
                  </li>

                  <li className="animate-box timeline-unverted">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">Programmer</h3>
                        <span className="company">Minda Perdana Indonesia, PT : Nov 2017 - Apr 2018</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">PHP</button>
                        <button className="btn btn-success btn-nopadding">Laravel</button>
                        <button className="btn btn-success btn-nopadding">CodeIgniter</button>
                        <button className="btn btn-success btn-nopadding">MySql</button>
                        <button className="btn btn-success btn-nopadding">PostgreSQL</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted animate-box">
                    <div className="timeline-badge">
                      <i className="icon-suitcase" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">Web Developer</h3>
                        <span className="company">Arundaya Rekayasa Teknologi : Jan 2016 - Nov 2017</span>
                      </div>
                      <div className="timeline-body">
                        <button className="btn btn-success btn-nopadding">PHP</button>
                        <button className="btn btn-success btn-nopadding">Laravel</button>
                        <button className="btn btn-success btn-nopadding">CodeIgniter</button>
                        <button className="btn btn-success btn-nopadding">MySql</button>
                        <button className="btn btn-success btn-nopadding">PostgreSQL</button>
                        <button className="btn btn-success btn-nopadding">Analyst</button>
                      </div>
                    </div>
                  </li>
                  <br />
                  <li className="timeline-heading text-center animate-box">
                    <div>
                      <h3>Education</h3>
                    </div>
                  </li>
                  <li className="animate-box timeline-unverted">
                    <div className="timeline-badge">
                      <i className="icon-graduation-cap" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">Bachelor's Degree</h3>
                        <span className="company">Pelita Bangsa University : 2018 - 2020</span>
                      </div>
                      <div className="timeline-body">
                        <Link href="https://www.pelitabangsa.ac.id/">Studied : Technology Information (https://www.pelitabangsa.ac.id)</Link>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted animate-box">
                    <div className="timeline-badge">
                      <i className="icon-graduation-cap" />
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h3 className="timeline-title">Diploma III</h3>
                        <span className="company">Al-Muslim - 2002 - 2006</span>
                      </div>
                      <div className="timeline-body">
                        <Link href="https://almuslim.ac.id/">Studied : Management Information (https://almuslim.ac.id)</Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="fh5co-features" className="animate-box">
          <div className="container">
            <div className="services-padding">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                  <h2>My Services</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center">
                  <div className="feature-left">
                    <span className="icon">
                      <i className="icon-paintbrush" />
                    </span>
                    <div className="feature-copy">
                      <h3>Web Development</h3>
                      <p>"I specialize in turning your brilliant ideas into high-quality web apps. Let's bring your vision to life online."</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="feature-left">
                    <span className="icon">
                      <i className="icon-briefcase" />
                    </span>
                    <div className="feature-copy">
                      <h3>Mobile App Development</h3>
                      <p>"I specialize in turning your brilliant ideas into high-quality mobile apps. Let's bring your vision to life on mobile platforms."</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="feature-left">
                    <span className="icon">
                      <i className="icon-search" />
                    </span>
                    <div className="feature-copy">
                      <h3>Tune Up Query</h3>
                      <p>"Experiencing slow query issues? Let me assist you in optimizing your database queries for improved performance."</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fh5co-skills" className="animate-box">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2>Skills</h2>
              </div>
            </div>
            <div className="row row-pb-md">
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={95}>
                  <span>
                    <strong>Node.JS</strong>95%
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={95}>
                  <span>
                    <strong>EJS</strong>95%
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={93}>
                  <span>
                    <strong>Flutter</strong>93%
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={50}>
                  <span>
                    <strong>Kotlin</strong>50%
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={99}>
                  <span>
                    <strong>Code Igniter</strong>99%
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={70}>
                  <span>
                    <strong>Laravel</strong>70%
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={90}>
                  <span>
                    <strong>SQL Query</strong>90%
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 text-center">
                <div className="chart" data-percent={85}>
                  <span>
                    <strong>Analyst</strong>85%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fh5co-work" className="fh5co-bg-color">
            <div className="container">
                <div className="row animate-box">
                    <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                        <h2>Portfolio</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 text-center" style={{marginBottom:"20px"}}>
                        <Link href="https://invoice.febriansyah.dev" >
                            <div className="card fh5co-bg-dark p-4" style={{padding:"20px",color:"black", height:"250px"}}>
                                <h3><u style={{color:"white"}} >Invoice System</u></h3>
                                <span>Laravel 10</span><br/>
                                <span>Mysql</span><br/>
                                <span>Javascript</span><br/>
                                <span>Ajax</span>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3 text-center" style={{marginBottom:"20px"}}>
                        <Link href="https://facerecognition.febriansyah.dev" >
                            <div className="card fh5co-bg-dark p-4" style={{padding:"20px",color:"black", height:"250px"}}>
                                <h3><u style={{color:"white"}} >Facerecognition</u></h3>
                                <span>Python</span><br/>
                                <span>Socket</span><br/>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3 text-center" style={{marginBottom:"20px"}}>
                        <Link href="https://github.com/iam-febriansyah/ckb-backend" >
                            <div className="card fh5co-bg-dark p-4" style={{padding:"20px",color:"black", height:"250px"}}>
                                <h3><u style={{color:"white"}} >Sample API</u></h3>
                                <span>Node JS</span><br/>
                                <span>Mysql</span><br/>
                                <span>Socket.io</span><br/>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3 text-center" style={{marginBottom:"20px"}}>
                        <Link href="https://github.com/iam-febriansyah/ckb-backend" >
                            <div className="card fh5co-bg-dark p-4" style={{padding:"20px",color:"black", height:"250px"}}>
                                <h3><u style={{color:"white"}} >Sample Flutter</u></h3>
                                <span>Flutter</span><br/>
                                <span>Socket.IO</span><br/>
                                <span>Hive</span><br/>
                                <span>Get State Management</span><br/>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3 text-center" style={{marginBottom:"20px"}}>
                        <Link href="https://github.com/iam-febriansyah/live-tiktok" >
                            <div className="card fh5co-bg-dark p-4" style={{padding:"20px",color:"black", height:"250px"}}>
                                <h3><u style={{color:"white"}} >Scraping Tiktok</u></h3>
                                <span>Node JS</span><br/>
                                <span>EJS</span><br/>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3 text-center" style={{marginBottom:"20px"}}>
                        <Link href="#" >
                            <div className="card fh5co-bg-dark p-4" style={{padding:"20px",color:"black", height:"250px"}}>
                                <h3><u style={{color:"white"}} >Scraping Web</u></h3>
                                <span>Python</span><br/>
                                <span>TLS Client</span><br/>
                                <span>Mongo DB</span><br/>
                                <span>Pandas</span><br/>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="fh5co-started" className="fh5co-bg-dark">
          <div className="overlay" />
          <div className="container">
            <div className="row animate-box">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2>Hire Me!</h2>
                <p>"I bring a wealth of experience in backend development with Node.js, mobile app development using Flutter, and a knack for optimizing database performance. I'm not just passionate about programming; I'm dedicated to delivering solutions that exceed expectations and drive tangible results."</p>
                <p>
                  <Link target="_blank" href="https://drive.google.com/file/d/1HhY4QkFe_pTRD2iRAtu7QCjDMFG0Si76/view?usp=sharing" className="btn btn-default btn-lg">
                    My Resume
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="fh5co-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>Big Thanks to FreeHTML5.co for this Web Design</p>
              </div>
            </div>
          </div>
        </div>
        <div className="gototop js-top">
          <Link href="#" className="js-gotop">
            <i className="icon-arrow-up22" />
          </Link>
        </div>
        <Modal />
      </div>
    </>
  );
}

export default Home;
