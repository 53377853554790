// components/Modal.js
import { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";

const Modal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // handleShow();
  }, []);

  return (
    <>
      <div className={`modal ${show ? "show" : ""}`} style={{ display: show ? "block" : "none" }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Hi, Thank you for visiting my website</h5>
              <button type="button" className="close" onClick={handleClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Just chat me for more details</p>
              <Link href="https://wa.me/628128182012">
                <Image src="/static/images/whatsapp.png" width={30} height={30} alt="628128182012" /> 628128182012
              </Link>
            </div>
            <div className="modal-footer">
              <Link href="https://wa.me/628128182012">
                <button type="button" className="btn btn-primary">
                  Chat Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default Modal;
